<template>
  <main id="finderScrollBox" class="flex flex-col items-center">
    <main-content-container>
      <h2 class="font-semibold  mb-6 mt-3 font-roboto text-xlg text-black">
        {{ $t("finder") }}
      </h2>

      <!-- params: { id: $route.params.id } -->
      <div data-testId="finder-router-link" class="flex mb-5 font-semibold">
        <router-link
          @click="setLastVisitedView('ProductCarrier')"
          :to="{ name: 'ProductCarrier' }"
          class="mr-2 cursor-pointer finder-link px-4 py-2 rounded-lg bg-white"
          >{{ $t("product_carrier") }}</router-link
        >
        <router-link
          @click="setLastVisitedView('Locations')"
          :to="{ name: 'Locations' }"
          class="mr-2 cursor-pointer finder-link px-4 py-2 rounded-lg bg-white"
          >{{ $t("locations") }}</router-link
        >
      </div>

      <!-- Content -->
      <router-view> </router-view>
    </main-content-container>
  </main>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import MainContentContainer from "../../components/global/MainContentContainer.vue";
import {pusherStore} from "../../common/thePusherService";
import {
  AUTH_TYPES,
  CUSTOMER_COLLIS_TYPES,
  CUSTOMER_POSITIONS_TYPES,
  DATA_FIELDS_TYPES,
  FINDERS_TYPES,
  STORE_TYPES,
} from "../../store/types";

export default defineComponent({
  components: {
    MainContentContainer,
  },
  props: {
    finderProps: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    /*
     * State
     */

    const state = reactive({});

    /*
     *Computeds
     */
    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    /*
     *Functions
     */

    function setLastVisitedView(viewName) {
      localStorage.setItem("lastVisitedSubRoute", viewName);
      console.log("TEST VIEW");
    }
    function FETCH_CUSTOMER_COLLIS() {
      return store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLIS}`,
        {
          id: GET_USER.value.customer_id,
        }
      );
    }
    function FETCH_FINDER_COLLIS() {
      const finderCollisData = {
        id: GET_USER.value.customer_id,
      };
      return store.dispatch(
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.FETCH_FINDER_COLLIS}`,
        finderCollisData
      );
    }
    function FETCH_FINDER_POSITIONS() {
      return store.dispatch(
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.FETCH_FINDER_POSITIONS}`,
        GET_USER.value.customer_id
      );
    }

    function FETCH_CUSTOMER_POSITIONS() {
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.FETCH_CUSTOMER_POSITIONS}`,
        GET_USER.value.customer_id
      );
    }

    function FETCH_DATA_FIELDS() {
      store.dispatch(
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.FETCH_DATA_FIELDS}`,
        GET_USER.value.customer_id
      );
    }
    onMounted(() => {
      if (localStorage.getItem("lastVisitedSubRoute")) {
        router.push({ name: localStorage.getItem("lastVisitedSubRoute") });
      }
    });

    /** Watch  */
    watch(
      () => GET_USER.value,
      async (newValue) => {
        if (newValue != null && newValue.customer_id != null) {
          if (props.finderProps == false) {
            await FETCH_CUSTOMER_COLLIS(newValue.customer_id);
            await FETCH_CUSTOMER_POSITIONS(newValue.customer_id);
          } else {
            await FETCH_FINDER_COLLIS(newValue.customer_id);
            await FETCH_FINDER_POSITIONS(newValue.customer_id);
            await FETCH_DATA_FIELDS(newValue.customer_id);
          }
          pusherStore.subscribeToUpdateCollis();
          pusherStore.subscribeToChangeCurrentPositions();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return { state, route, store, setLastVisitedView };
  },
});
</script>

<style lang="scss">
.finder-link {
  &.router-link-exact-active {
    @apply bg-primary;
    @apply text-white;
  }
}
</style>
